<template>
  <van-icon name="arrow-left" class="back" size="20" @click="toBack" />
  <div class="register">
    <div class="register__title">注册</div>
    <div class="register__input">
      <van-field
        class="register__input__input"
        v-model="username"
        placeholder="请输入账号"
      />
      <van-field
        class="register__input__input"
        v-model="email"
        placeholder="请输入注册邮箱"
      />
      <van-field
        v-model="password"
        type="password"
        class="register__input__input"
        placeholder="请输入密码"
      />
      <van-field
        v-model="password2"
        type="password"
        class="register__input__input"
        placeholder="请再次输入密码"
      />
    </div>
    <div class="register__other">
      <div class="register__other__text" @click="toLogin">已有账号？</div>
      <div class="register__other__text" @click="toRetrieve">忘记密码</div>
    </div>
    <div class="register__btn">
      <van-button
        class="register__btn__btn"
        type="primary"
        block
        @click="register"
        >注册</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Toast, Dialog } from "vant";

// 登陆注册
const useregisterEffect = () => {
  const username = ref("");
  const email = ref("");
  const password = ref("");
  const password2 = ref("");
  const store = useStore();
  const router = useRouter();
  const register = () => {
    if (username.value && email.value && password.value) {
      const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!reg.test(email.value)) return Toast.fail("邮箱格式不对");
      if (password.value !== password2.value)
        return Toast.fail("两次输入的密码不一致");

      store
        .dispatch("user/register", {
          username: username.value.trim(),
          email: email.value,
          password: password.value.trim(),
        })
        .then((res) => {
          if (res) {
            Toast.success("注册成功");
            store
              .dispatch("user/login", {
                username: username.value.trim(),
                password: password.value.trim(),
              })
              .then((res) => {
                Dialog.alert({
                  title: "注册用户",
                  message:
                    "请确保邮箱的正确性，链接30分钟内有效，请及时到邮箱中确认",
                }).then(() => {
                  if (res) {
                    setTimeout(() => {
                      router.push({ name: "home" });
                    }, 1000);
                    return;
                  }
                });
              })
          }
        });

      Toast.fail("注册失败");
    } else {
      Toast("请输入完整");
    }
  };
  return { username, email, password, password2, register };
};

// 路由跳转
const useRouterEffect = () => {
  const router = useRouter();
  const toBack = () => {
    history.back();
  };
  const toLogin = () => {
    router.push({ name: "login" });
  };
  const toRetrieve = () => {
    router.push({ name: "retrieve" });
  };
  return { toBack, toLogin, toRetrieve };
};

export default {
  setup() {
    const { username, email, password, password2, register } =
      useregisterEffect();
    const { toBack, toLogin, toRetrieve } = useRouterEffect();
    return {
      username,
      email,
      password,
      password2,
      register,
      toBack,
      toLogin,
      toRetrieve,
    };
  },
};
</script>

<style lang="less" scoped>
.back {
  padding: 20px 15px;
}
.register {
  padding: 20px 50px;
  &__title {
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 5px;
    margin-bottom: 10px;
  }
  &__input {
    padding: 15px 0;
    margin: 0 -15px;
    &__input {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__other {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 25px 0;
    &__text {
      text-decoration: underline;
      font-size: 80%;
      letter-spacing: 1px;
    }
  }
  &__btn {
    &__btn {
      border-radius: 10px;
      height: 35px;
      letter-spacing: 10px;
      font-size: 15px;
    }
  }
}
</style>