<template>
  <van-icon name="arrow-left" class="back" size="20" @click="toBack" />
  <div class="login">
    <div class="login__title">登陆</div>
    <div class="login__input">
      <van-field
        class="login__input__input"
        v-model="username"
        placeholder="请输入账号或邮箱"
      />
      <van-field v-model="password" type="password" placeholder="请输入密码" />
    </div>
    <div class="login__other">
      <div class="login__other__text" @click="toRegister">立即注册</div>
      <div class="login__other__text" @click="toRetrieve">忘记密码</div>
    </div>
    <div class="login__btn">
      <van-button class="login__btn__btn" type="primary" block @click="login"
        >登陆</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Toast } from "vant";

// 登陆注册
const useLoginEffect = () => {
  const username = ref("");
  const password = ref("");
  const store = useStore();
  const router = useRouter();
  const login = () => {
    store
      .dispatch("user/login", {
        username: username.value.trim(),
        password: password.value.trim(),
      })
      .then((res) => {
        if (res) {
          Toast.success("登陆成功");
          setTimeout(() => {
            router.push({ name: "home" });
          }, 1000);
          store.dispatch("app/getUserSetting");
          setTimeout(() => {
            router.go(0);
          }, 1100);
        }
      });
  };
  return { username, password, login };
};

// 路由跳转
const useRouterEffect = () => {
  const router = useRouter();
  const toBack = () => {
    history.back();
  };
  const toRegister = () => {
    router.push({ name: "register" });
  };
  const toRetrieve = () => {
    router.push({ name: "retrieve" });
  };
  return { toBack, toRegister, toRetrieve };
};

export default {
  setup() {
    const { username, password, login } = useLoginEffect();
    const { toBack, toRegister, toRetrieve } = useRouterEffect();
    return { username, password, login, toBack, toRegister, toRetrieve };
  },
};
</script>

<style lang="less" scoped>
.back {
  padding: 20px 15px;
}
.login {
  padding: calc(20vh - 60px) 50px;
  &__title {
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 5px;
    margin-bottom: 10px;
  }
  &__input {
    padding: 15px 0;
    margin: 0 -15px;
    &__input {
      margin-bottom: 20px;
    }
  }
  &__other {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 25px 0;
    &__text {
      text-decoration: underline;
      font-size: 80%;
      letter-spacing: 1px;
    }
  }
  &__btn {
    &__btn {
      border-radius: 10px;
      height: 35px;
      letter-spacing: 10px;
      font-size: 15px;
    }
  }
}
</style>