<template>
  <van-icon name="arrow-left" class="back" size="20" @click="toBack" />
  <div class="retrieve">
    <div class="retrieve__title">忘记密码</div>
    <div class="retrieve__input">
      <van-field
        v-model="username"
        placeholder="请输入账号"
        v-show="is_username"
      />
      <van-field
        v-model="email"
        placeholder="请输入邮箱"
        v-show="!is_username"
      />
    </div>
    <div class="retrieve__other" @click="toRetrieve()">
      {{ is_username ? "使用邮箱找回" : "使用账号找回" }}
    </div>
    <div class="retrieve__btn">
      <van-button
        class="retrieve__btn__btn"
        type="primary"
        block
        @click="retrieve"
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Toast, Dialog } from "vant";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const username = ref("");
    const email = ref("");
    const is_username = ref(true);
    const toBack = () => {
      history.back();
    };
    const toRetrieve = () => {
      setTimeout(() => {
        is_username.value = !is_username.value;
        username.value = "";
        email.value = "";
      }, 200);
    };
    const retrieve = () => {
      if (username.value || email.value) {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (email.value && !reg.test(email.value)) {
          return Toast.fail("邮箱格式不对");
        }
        store
          .dispatch("user/postPasswordEmail", {
            username: username.value.trim(),
            email: email.value.trim(),
          })
          .then((res) => {
            if (res) {
              Dialog.alert({
                title: "重置密码",
                message: "重置邮件已发至邮箱，链接30分钟内有效，请及时修改",
              }).then(() => {
                if (res) {
                  setTimeout(() => {
                    router.push({ name: "login" });
                  }, 1000);
                  return;
                }
              });
            }
          });
      } else {
        Toast("不能为空");
      }
    };
    return { username, email, retrieve, is_username, toBack, toRetrieve };
  },
};
</script>

<style lang="less" scoped>
.back {
  padding: 20px 15px;
}
.retrieve {
  padding: calc(20vh - 60px) 50px;
  &__title {
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 5px;
    margin-bottom: 10px;
  }
  &__input {
    padding: 15px 0;
    margin: 0 -15px;
  }
  &__other {
    text-align: center;
    padding: 10px 0 20px 0;
    text-decoration: underline;
    font-size: 80%;
    letter-spacing: 1px;
  }
  &__btn {
    &__btn {
      border-radius: 10px;
      height: 35px;
      letter-spacing: 10px;
      font-size: 15px;
    }
  }
}
</style>